<template>
  <div class="main">
    <div>
      <h2>{{ $t(`msg.${name}.title`) }}</h2>
      <p class="my-3">{{ $t(`msg.${name}.subtitle`) }}</p>
      <div class="card">
        <div class="card-header card-header-flex py-3">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-6">
                <!-- Search Bar -->
                <a-input-search
                  :placeholder="$t('search')"
                  style="width: 200px"
                  @search="onSearch"
                  v-if="features.search"
                  allow-clear />
              </div>
              <div class="col-6 text-right">
                <router-link
                  :to="`${$route.path}/create`"
                  v-if="features.create && $auth.granted(acl[`${name}s`].create)">
                  <a-button
                    class="d-md-none"
                    icon="plus"
                    type="primary"
                    shape="circle" />
                  <a-button
                    class="d-none d-md-inline"
                    icon="plus-circle"
                    type="primary"
                    size="large">
                    {{ $t('msg.abm.action.create') }} {{ $t(name) }}
                  </a-button>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body p-0">
          <a-list
            :data-source="results.data"
            :pagination="{
              onChange,
              current: pagination.page,
              defaultPageSize: pagination.size,
              total: results.items,
              simple: true }">
            <a-list-item
              slot="renderItem"
              slot-scope="item"
              class="p-4">
              <template #actions>
                <slot name="actions" v-bind="{item, type: 'icon'}">
                  <router-link
                    :to="`${$route.path}/${item.id}/update`"
                    v-if="features.update && $auth.granted(acl[`${name}s`].update)">
                    <a-button
                      shape="circle"
                      icon="setting" />
                  </router-link>
                </slot>
              </template>
              <a-list-item-meta>
                <template #title>
                  <slot name="row" v-bind:item="item">
                    <pre>
                      <code>{{ item }}</code>
                    </pre>
                  </slot>
                </template>
                <template #avatar>
                  <slot name="avatar" v-bind:item="item" />
                </template>
              </a-list-item-meta>
            </a-list-item>
          </a-list>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import acl from '@/auth/acl'
export default {
  name: 'JAbmList',
  props: [
    'name',
    'avatar',
    'features',
    'suppliers',
  ],
  data: function () {
    return {
      acl,
    }
  },
  computed: {
    results() {
      return this.$store.getters['results/DATA'](this.name)
    },
    pagination() {
      return this.$store.getters['results/PAGE'](this.name)
    },
  },
  watch: {
    pagination: {
      deep: false,
      immediate: true,
      handler: 'fetch',
    },
  },
  methods: {
    fetch() {
      return this.suppliers.fetchAll(this.pagination)
        .then(r => this.$store.commit('results/SET_DATA', { key: this.name, body: r.data }))
    },
    onSearch(query) {
      if (query) {
        this.suppliers.search(query)
          .then(r => this.$store.commit('results/SET_DATA', { key: this.name, body: r.data }))
      } else {
        this.fetch()
      }
    },
    onChange(page) {
      window.scrollTo(0, 0)
      const pagination = { page, size: this.pagination.size }
      this.$store.commit('results/SET_PAGE', { key: this.name, body: pagination })
    },
  },
}
</script>
