var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('j-abm',{attrs:{"name":"payment","avatar":"credit-card","suppliers":_vm.suppliers,"features":{
    search: true,
    create: false,
    update: false,
    delete: false,
  }},scopedSlots:_vm._u([{key:"avatar",fn:function(){return [_c('a-avatar',{staticClass:"d-none d-lg-block",attrs:{"src":"resources/images/platforms/MPA.png","shape":"square","size":48}})]},proxy:true},{key:"row",fn:function(ref){
  var item = ref.item;
return [_c('div',[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6 text-monospace"},[_c('h6',[_vm._v(" "+_vm._s(item.code)+" "),_c('a-tooltip',{attrs:{"placement":"right"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.$t(("enum.payment." + (item.status))))+" ")])]},proxy:true}],null,true)},[_c('a-icon',{class:_vm.pss[item.status].color,attrs:{"type":_vm.pss[item.status].icon}})],1)],1),_c('j-label',{attrs:{"icon":"number"}},[_c('a-tag',[_vm._v(_vm._s(item.ref))])],1)],1),_c('div',{staticClass:"col-12 col-md-6"},[_c('j-label',{attrs:{"icon":"dollar"}},[_vm._v(" "+_vm._s(_vm._f("currency")(item.value))+" ")]),_c('j-label',{attrs:{"icon":"mail"}},[_c('j-email',{attrs:{"value":item.email}})],1),(item.approved)?_c('j-label',{attrs:{"icon":"check-circle"}},[_c('span',{attrs:{"title":_vm._f("datetime")(item.approved)}},[_vm._v(" "+_vm._s(_vm.$t('msg.payment.approved'))+" "+_vm._s(_vm._f("elapsed")(item.approved))+" ")])]):_c('j-label',{attrs:{"icon":"hourglass"}},[_vm._v(" "+_vm._s(_vm.$t('msg.payment.pending'))+" ")])],1)])])])]}},{key:"actions",fn:function(ref){
  var item = ref.item;
return [_c('a',{attrs:{"href":item.receipt,"target":"_blank"}},[_c('a-button',{attrs:{"shape":"circle","icon":"paper-clip"}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }