<template>
  <div>
    <a-icon :type="icon" class="text-secondary" />
    <span class="ml-1">
      <slot></slot>
    </span>
  </div>
</template>

<script>
export default {
  name: 'LabelText',
  props: [
    'icon',
    'text',
    'type',
  ],
}
</script>
