<template>
  <j-abm
    name="payment"
    avatar="credit-card"
    :suppliers="suppliers"
    :features="{
      search: true,
      create: false,
      update: false,
      delete: false,
    }">
    <!-- Avatar -->
    <template #avatar>
      <a-avatar
        class="d-none d-lg-block"
        src="resources/images/platforms/MPA.png"
        shape="square"
        :size="48" />
    </template>
    <!-- Row -->
    <template #row="{item}">
      <div>
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-6 text-monospace">
              <h6>
                {{ item.code }}
                <!-- Status -->
                <a-tooltip
                  placement="right">
                  <template #title>
                    <span>
                      {{ $t(`enum.payment.${item.status}`) }}
                    </span>
                  </template>
                  <a-icon
                    :type="pss[item.status].icon"
                    :class="pss[item.status].color" />
                </a-tooltip>
              </h6>
              <!-- Ref -->
              <j-label icon="number">
                <a-tag>{{ item.ref }}</a-tag>
              </j-label>
            </div>
            <!-- Info -->
            <div class="col-12 col-md-6">
              <j-label icon="dollar">
                {{ item.value | currency }}
              </j-label>
              <j-label icon="mail">
                <j-email :value="item.email" />
              </j-label>
              <j-label icon="check-circle" v-if="item.approved">
                <span :title="item.approved | datetime">
                  {{ $t('msg.payment.approved') }} {{ item.approved | elapsed }}
                </span>
              </j-label>
              <j-label icon="hourglass" v-else>
               {{ $t('msg.payment.pending') }}
              </j-label>
            </div>
          </div>
        </div>
      </div>
    </template>
    <!-- Actions -->
    <template #actions="{item}">
      <a :href="item.receipt" target="_blank">
      <a-button shape="circle" icon="paper-clip" />
      </a>
    </template>
  </j-abm>
</template>

<script>
import lms from '@/api/lms'
import acl from '@/auth/acl'
import { paymentStatusStyle } from '@/utils/styles'
import JAbm from '@/views/shared/abm/search'
import JLabel from '@/views/shared/labels/text'
import JEmail from '@/views/shared/labels/email'
export default {
  components: {
    JAbm,
    JLabel,
    JEmail,
  },
  data: function () {
    return {
      acl,
      pss: paymentStatusStyle,
    }
  },
  computed: {
    suppliers() {
      return {
        search: query => lms.payment.search(query),
        fetchAll: page => lms.payment.fetchAll(page),
      }
    },
  },
}
</script>
