export const status = {
  TO_COLLECT: {
    icon: 'fe-printer',
    color: 'DodgerBlue',
    preset: 'blue',
    level: 0,
  },
  COLLECTED: {
    icon: 'fe-check',
    color: 'DodgerBlue',
    preset: 'blue',
    level: 0,
  },
  TO_RECOVER: {
    icon: 'fe-clock',
    color: 'DodgerBlue',
    preset: 'blue',
    level: 0,
  },
  RECOVERED: {
    icon: 'fe-check',
    color: 'DodgerBlue',
    preset: 'blue',
    level: 0,
  },
  TO_RECEIVE: {
    icon: 'fe-map-pin',
    color: 'DodgerBlue',
    preset: 'blue',
    level: 0,
  },
  RECEIVED: {
    icon: 'fe-check',
    color: 'DodgerBlue',
    preset: 'blue',
    level: 0,
  },
  TO_DELIVER: {
    icon: 'fe-home',
    color: 'DodgerBlue',
    preset: 'blue',
    level: 0,
  },
  DELIVERED: {
    icon: 'fe-check',
    color: 'Teal',
    preset: 'green',
    level: 0,
  },
  TO_RETURN: {
    icon: 'fe-corner-up-left',
    color: 'DodgerBlue',
    preset: 'blue',
    level: 0,
  },
  RETURNED: {
    icon: 'fe-check',
    color: 'Teal',
    preset: 'green',
    level: 0,
  },
  CANCELED: {
    icon: 'fe-x',
    color: 'LightSlateGray',
    preset: 'grey',
    level: 0,
  },
  TO_CONSOLIDATE: {
    icon: 'fe-layers',
    color: 'DodgerBlue',
    preset: 'blue',
    level: 0,
  },
  CONSOLIDATED: {
    icon: 'fe-check',
    color: 'Teal',
    preset: 'green',
    level: 0,
  },
  BLOCKED: {
    icon: 'fe-lock',
    color: 'Crimson',
    preset: 'red',
    level: 1,
  },
  DISPATCHED: {
    icon: 'fe-log-out',
    color: 'DodgerBlue',
    preset: 'blue',
    level: 1,
  },
  ADMITTED: {
    icon: 'fe-log-in',
    color: 'DodgerBlue',
    preset: 'blue',
    level: 1,
  },
  CONFIRMED: {
    icon: 'fe-navigation',
    color: 'DodgerBlue',
    preset: 'blue',
    level: 1,
  },
  VISITED: {
    icon: 'fe-map-pin',
    color: 'DodgerBlue',
    preset: 'blue',
    level: 1,
  },
  NOT_VISITED: {
    icon: 'fe-slash',
    color: 'DarkOrange',
    preset: 'orange',
    level: 1,
  },
  PICK_UP: {
    icon: 'fe-map-pin',
    color: 'DodgerBlue',
    preset: 'blue',
    level: 1,
  },
  ABORTED: {
    icon: 'fe-x',
    color: 'Crimson',
    preset: 'red',
    level: 1,
  },
  EXPIRED: {
    icon: 'fe-map-pin',
    color: 'DarkOrange',
    preset: 'orange',
    level: 1,
  },
  TO_APPROVE: {
    icon: 'fe-clock',
    color: 'LightSlateGray',
    preset: 'grey',
    level: 1,
  },
  PRINTED: {
    icon: 'fe-package',
    color: 'DodgerBlue',
    preset: 'blue',
    level: -1,
  },
}

export const direction = {
  FORWARD: {
    icon: 'arrow-right',
    color: 'green',
  },
  BACKWARD: {
    icon: 'arrow-left',
    color: 'red',
  },
}

export const operation = {
  DELIVERY: {
    icon: 'arrow-right',
    color: 'green',
  },
  RETURN: {
    icon: 'arrow-left',
    color: 'red',
  },
  EXCHANGE: {
    icon: 'retweet',
    color: 'blue',
  },
}

export const activation = {
  ACTIVE: {
    color: 'success',
  },
  PENDING: {
    color: 'warning',
  },
  INACTIVE: {
    color: 'default',
  },
}

export const courierType = {
  SINGLE: {
    icon: 'user',
  },
  COMPANY: {
    icon: 'amazon',
  },
}

export const method = {
  EXPRESS: {
    color: 'green',
  },
}

export const paymentStatusStyle = {
  APPROVED: {
    icon: 'check-circle',
    color: 'text-success',
  },
  PROCESSING: {
    icon: 'clock-circle',
    color: 'text-default',
  },
  PENDING: {
    icon: 'clock-circle',
    color: 'text-default',
  },
  REJECTED: {
    icon: 'exclamation-circle',
    color: 'text-danger',
  },
}
