<template>
  <a :href="`mailto:${value}`" target="_blank">
    {{ value }}
  </a>
</template>

<script>
export default {
  name: 'LabelEmail',
  props: [
    'value',
  ],
}
</script>
